import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup2MobileSlice = createSlice({
  name: 'playgroup2mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup2_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup2_mobile} = PlayGroup2MobileSlice.actions;
export default PlayGroup2MobileSlice.reducer;
