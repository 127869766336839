import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PlayGroupDashboard from "./PlayGroupDashboard";
import PlayGroupMenu from "./PlayGroupMenu";
import UserList from "./UserList";
const RoutingComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/playgroupMenu" element={<PlayGroupMenu />} />
        <Route path="/playgroupDashboard" element={<PlayGroupDashboard />} />
        <Route path="/playgroupUsers" element={<UserList />} />
      </Routes>
    </Router>
  );
};
export default RoutingComponent;
