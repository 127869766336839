// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXXBmGQ4DLVF1mbBIA23_zPfn9zrvrqH8",
  authDomain: "playgroup-37c92.firebaseapp.com",
  projectId: "playgroup-37c92",
  storageBucket: "playgroup-37c92.appspot.com",
  messagingSenderId: "599358214622",
  appId: "1:599358214622:web:1634fdb20f2ca8cb7a8d4f",
  measurementId: "G-Z4Q5TZ0G5P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
