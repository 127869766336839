import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup6MobileSlice = createSlice({
  name: 'playgroup6mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup6_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup6_mobile} = PlayGroup6MobileSlice.actions;
export default PlayGroup6MobileSlice.reducer;
