import React, { useEffect } from "react";
import "./styles.css";
import { db } from "./FirebaseInit.js";
import { collection, getCountFromServer } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { populatePlaygroupDashboard } from "./Redux/PlayGroupDashboardSlice";
import PlayGroup1Icon from "../Assets/PlayGroup1.png";
import PlayGroup2Icon from "../Assets/PlayGroup2.png";
import PlayGroup3Icon from "../Assets/PlayGroup3.png";
import PlayGroup4Icon from "../Assets/PlayGroup4.png";
import PlayGroup5Icon from "../Assets/PlayGroup5.png";
import PlayGroup6Icon from "../Assets/PlayGroup6.png";
import PlayGroup7Icon from "../Assets/PlayGroup7.png";
import PlayGroupAllIcon from "../Assets/PlayGroupAll.jpeg";
import WingsTamilIcon from "../Assets/WingsTamilIcon.png";
function PlayGroupMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let arrayOfIcons = [
    PlayGroup1Icon,
    PlayGroup2Icon,
    PlayGroup3Icon,
    PlayGroup4Icon,
    PlayGroup5Icon,
    PlayGroup6Icon,
    PlayGroup7Icon,
    PlayGroupAllIcon,
    WingsTamilIcon,
  ];
  const playgroupDashboardData = useSelector(
    (state) => state.callPlayGroupDashboard.value
  );
  useEffect(() => {
    if (playgroupDashboardData.playgroup1 == null) {
      getTheCounts();
    }
  }, []);
  async function getTheCounts() {
    console.log("Get The Counts From Server");
    const playgroup1Collection = collection(db, "PlayGroup1");
    const playgroup1Snapshot = await getCountFromServer(playgroup1Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup1",
        sol: playgroup1Snapshot.data().count,
      })
    );
    const playgroup2Collection = collection(db, "PlayGroup2");
    const playgroup2Snapshot = await getCountFromServer(playgroup2Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup2",
        sol: playgroup2Snapshot.data().count,
      })
    );
    const playgroup3Collection = collection(db, "PlayGroup3");
    const playgroup3Snapshot = await getCountFromServer(playgroup3Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup3",
        sol: playgroup3Snapshot.data().count,
      })
    );
    const playgroup4Collection = collection(db, "PlayGroup4");
    const playgroup4Snapshot = await getCountFromServer(playgroup4Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup4",
        sol: playgroup4Snapshot.data().count,
      })
    );
    const playgroup5Collection = collection(db, "PlayGroup5");
    const playgroup5Snapshot = await getCountFromServer(playgroup5Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup5",
        sol: playgroup5Snapshot.data().count,
      })
    );
    const playgroup6Collection = collection(db, "PlayGroup6");
    const playgroup6Snapshot = await getCountFromServer(playgroup6Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup6",
        sol: playgroup6Snapshot.data().count,
      })
    );
    const playgroup7Collection = collection(db, "PlayGroup7");
    const playgroup7Snapshot = await getCountFromServer(playgroup7Collection);
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroup7",
        sol: playgroup7Snapshot.data().count,
      })
    );
    const playgroupAllCollection = collection(db, "PlayGroupAll");
    const playgroupAllSnapshot = await getCountFromServer(
      playgroupAllCollection
    );
    dispatch(
      populatePlaygroupDashboard({
        type: "playgroupAll",
        sol: playgroupAllSnapshot.data().count,
      })
    );
    const wingsTamilCollection = collection(db, "WingsTamil");
    const wingsTamilSnapshot = await getCountFromServer(wingsTamilCollection);
    dispatch(
      populatePlaygroupDashboard({
        type: "wingsTamil",
        sol: wingsTamilSnapshot.data().count,
      })
    );
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        rowGap: 25,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <h1>User's data for the following applications</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          columnGap: 10,
        }}
      >
        {arrayOfIcons.map((item, index) => (
          <img
            style={{ width: 100, height: 100, object: "contain", flex: "none" }}
            src={item}
            key={index}
          />
        ))}
      </div>
      <div class="menubuttons" onClick={() => navigate("/playgroupDashboard")}>
        Dashboard
      </div>
      <div class="menubuttons" onClick={() => navigate("/playgroupUsers")}>
        Users List
      </div>
      {/* <div
        class="menubuttons"
        style={{ color: "#FFFFFF", backgroundColor: "#022C43" }}
      >
        Back
      </div> */}
      {/* <button
        style={{
          position: "absolute",
          top: 50,
          left: 50,
          backgroundColor: "#022C43",
          fontSize: 30,
          color: "#FFFFFF",
          fontWeight: "bold",
          borderRadius: 9,
          width: 200,
          height: 50,
        }}
      >
        Back
      </button> */}
    </div>
  );
}
export default PlayGroupMenu;
