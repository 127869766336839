import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup5MobileSlice = createSlice({
  name: 'playgroup5mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup5_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup5_mobile} = PlayGroup5MobileSlice.actions;
export default PlayGroup5MobileSlice.reducer;
