import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup4MobileSlice = createSlice({
  name: 'playgroup4mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup4_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup4_mobile} = PlayGroup4MobileSlice.actions;
export default PlayGroup4MobileSlice.reducer;
