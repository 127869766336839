import {createSlice} from '@reduxjs/toolkit';
export const PlayGroupAllMobileSlice = createSlice({
  name: 'playgroupallmobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroupall_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroupall_mobile} = PlayGroupAllMobileSlice.actions;
export default PlayGroupAllMobileSlice.reducer;
