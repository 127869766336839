import React, { useState, useEffect } from "react";
import { db } from "../Components/FirebaseInit.js";
import { collection, getCountFromServer } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import PlayGroup1Icon from "../Assets/PlayGroup1.png";
import PlayGroup2Icon from "../Assets/PlayGroup2.png";
import PlayGroup3Icon from "../Assets/PlayGroup3.png";
import PlayGroup4Icon from "../Assets/PlayGroup4.png";
import PlayGroup5Icon from "../Assets/PlayGroup5.png";
import PlayGroup6Icon from "../Assets/PlayGroup6.png";
import PlayGroup7Icon from "../Assets/PlayGroup7.png";
import PlayGroupAllIcon from "../Assets/PlayGroupAll.jpeg";
import WingsTamilIcon from "../Assets/WingsTamilIcon.png";
import BackArrowIcon from "../Assets/BackArrowIcon.png";
import { useNavigate } from "react-router-dom";
function PlayGroupDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const playgroupDashboardData = useSelector(
    (state) => state.callPlayGroupDashboard.value
  );
  const [applicationArray, setApplicationArray] = useState([
    { name: "PlayGroup1", toDisplay: "Play Group 1" },
    { name: "PlayGroup2", toDisplay: "Play Group 2" },
    { name: "PlayGroup3", toDisplay: "Play Group 3" },
    { name: "PlayGroup4", toDisplay: "Play Group 4" },
    { name: "PlayGroup5", toDisplay: "Play Group 5" },
    { name: "PlayGroup6", toDisplay: "Play Group 6" },
    { name: "PlayGroup7", toDisplay: "Play Group 7" },
    { name: "PlayGroupAll", toDisplay: "Play Group All" },
    { name: "WingsTamil", toDisplay: "Wings Tamil" },
  ]);
  const returnTheActualCount = (value) => {
    switch (value) {
      case "PlayGroup1":
        return playgroupDashboardData.playgroup1.toString().length < 3
          ? playgroupDashboardData.playgroup1.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup1.toString();
      case "PlayGroup2":
        return playgroupDashboardData.playgroup2.toString().length < 3
          ? playgroupDashboardData.playgroup2.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup2.toString();
      case "PlayGroup3":
        return playgroupDashboardData.playgroup3.toString().length < 3
          ? playgroupDashboardData.playgroup3.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup3.toString();
      case "PlayGroup4":
        return playgroupDashboardData.playgroup4.toString().length < 3
          ? playgroupDashboardData.playgroup4.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup4.toString();
      case "PlayGroup5":
        return playgroupDashboardData.playgroup5.toString().length < 3
          ? playgroupDashboardData.playgroup5.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup5.toString();
      case "PlayGroup6":
        return playgroupDashboardData.playgroup6.toString().length < 3
          ? playgroupDashboardData.playgroup6.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup6.toString();
      case "PlayGroup7":
        return playgroupDashboardData.playgroup7.toString().length < 3
          ? playgroupDashboardData.playgroup7.toString().padStart(3, 0)
          : playgroupDashboardData.playgroup7.toString();
      case "PlayGroupAll":
        return playgroupDashboardData.playgroupAll.toString().length < 3
          ? playgroupDashboardData.playgroupAll.toString().padStart(3, 0)
          : playgroupDashboardData.playgroupAll.toString();
      case "WingsTamil":
        return playgroupDashboardData.wingsTamil.toString().length < 3
          ? playgroupDashboardData.wingsTamil.toString().padStart(3, 0)
          : playgroupDashboardData.wingsTamil.toString();
    }
  };
  const returnTheAppIcon = (value) => {
    switch (value) {
      case "PlayGroup1":
        return PlayGroup1Icon;
        break;
      case "PlayGroup2":
        return PlayGroup2Icon;
        break;
      case "PlayGroup3":
        return PlayGroup3Icon;
        break;
      case "PlayGroup4":
        return PlayGroup4Icon;
        break;
      case "PlayGroup5":
        return PlayGroup5Icon;
        break;
      case "PlayGroup6":
        return PlayGroup6Icon;
        break;
      case "PlayGroup7":
        return PlayGroup7Icon;
        break;
      case "PlayGroupAll":
        return PlayGroupAllIcon;
        break;
      case "WingsTamil":
        return WingsTamilIcon;
        break;
    }
  };
  async function getMetadata() {}
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        backgroundColor: "yellow",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "95%",
          alignItems: "center",
          paddingLeft: "2.5%",
          paddingRight: "2.5%",
        }}
      >
        {" "}
        <img
          src={BackArrowIcon}
          style={{
            width: 50,
            height: 50,
            object: "contain",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
        <h1>User count per application</h1>
        <div
          style={{ width: 50, height: 50, backgroundColor: "transparent" }}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto",
          overflow: "auto",
          width: "95%",
          height: "95%",
          //border: "2px solid black",
          justifyContent: "space-between",
          padding: 10,
          gap: 10,
        }}
      >
        {applicationArray.map((item, index) => (
          <div
            key={index}
            style={{
              width: 350,
              height: 200,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "purple",
              padding: 10,
              borderRadius: 9,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <h1 style={{ color: "yellow" }}>{item.toDisplay}</h1>
            <h2 style={{ color: "yellow" }}>
              {returnTheActualCount(item.name)}
            </h2>
            <img
              src={returnTheAppIcon(item.name)}
              style={{
                width: 100,
                height: 100,
                object: "contein",
                bottom: 5,
                right: 5,
                position: "absolute",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
export default PlayGroupDashboard;
