import { createSlice } from "@reduxjs/toolkit";
export const PlayGroupDashboardSlice = createSlice({
  name: "playgroupDashboard",
  initialState: {
    value: {
      playgroup1: null,
      playgroup2: null,
      playgroup3: null,
      playgroup4: null,
      playgroup5: null,
      playgroup6: null,
      playgroup7: null,
      playgroupAll: null,
      wingsTamil: null,
    },
  },
  reducers: {
    populatePlaygroupDashboard: (state, action) => {
      switch (action.payload.type) {
        case "playgroup1":
          state.value.playgroup1 = action.payload.sol;
          break;
        case "playgroup2":
          state.value.playgroup2 = action.payload.sol;
          break;
        case "playgroup3":
          state.value.playgroup3 = action.payload.sol;
          break;
        case "playgroup4":
          state.value.playgroup4 = action.payload.sol;
          break;
        case "playgroup5":
          state.value.playgroup5 = action.payload.sol;
          break;
        case "playgroup6":
          state.value.playgroup6 = action.payload.sol;
          break;
        case "playgroup7":
          state.value.playgroup7 = action.payload.sol;
          break;
        case "playgroupAll":
          state.value.playgroupAll = action.payload.sol;
          break;
        case "wingsTamil":
          state.value.wingsTamil = action.payload.sol;
          break;
      }
    },
  },
});
export const { populatePlaygroupDashboard } = PlayGroupDashboardSlice.actions;
export default PlayGroupDashboardSlice.reducer;
