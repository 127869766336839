import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup1MobileSlice = createSlice({
  name: 'playgroup1mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup1_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup1_mobile} = PlayGroup1MobileSlice.actions;
export default PlayGroup1MobileSlice.reducer;
