import {createSlice} from '@reduxjs/toolkit';
export const PlayGroup7MobileSlice = createSlice({
  name: 'playgroup7mobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_playgroup7_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_playgroup7_mobile} = PlayGroup7MobileSlice.actions;
export default PlayGroup7MobileSlice.reducer;
