import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  getDocs,
  orderBy,
  limit,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "./FirebaseInit";
import { populate_playgroup1_mobile } from "./Redux/PlayGroup1MobileSlice";
import { populate_playgroup2_mobile } from "./Redux/PlayGroup2MobileSlice";
import { populate_playgroup3_mobile } from "./Redux/PlayGroup3MobileSlice";
import { populate_playgroup4_mobile } from "./Redux/PlayGroup4MobileSlice";
import { populate_playgroup5_mobile } from "./Redux/PlayGroup5MobileSlice";
import { populate_playgroup6_mobile } from "./Redux/PlayGroup6MobileSlice";
import { populate_playgroup7_mobile } from "./Redux/PlayGroup7MobileSlice";
import { populate_playgroupall_mobile } from "./Redux/PlayGroupAllMobileSlice";
import { populate_wingstamil_mobile } from "./Redux/WingsTamilMobileSlice";
import ExportExcelIcon from "../Assets/ExportExcelIcon.png";
import BackArrowIcon from "../Assets/BackArrowIcon.png";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
function UserList() {
  const navigate = useNavigate();
  const csvHeaders = [
    { label: "Sl.", key: "sl" },
    { label: "Player Name", key: "playerName" },
    { label: "Player Age", key: "playerAge" },
    { label: "Player School", key: "playerSchool" },
    { label: "Player Mobile Number", key: "playerMobileNumber" },
    { label: "Player Town", key: "playerTown" },
    { label: "Playing since", key: "playingSince" },
  ];
  const dispatch = useDispatch();
  const playgroupDashboardData = useSelector(
    (state) => state.callPlayGroupDashboard.value
  );
  const playgroup1Data = useSelector(
    (state) => state.callPlayGroup1Mobile.value
  );
  const playgroup2Data = useSelector(
    (state) => state.callPlayGroup2Mobile.value
  );
  const playgroup3Data = useSelector(
    (state) => state.callPlayGroup3Mobile.value
  );
  const playgroup4Data = useSelector(
    (state) => state.callPlayGroup4Mobile.value
  );
  const playgroup5Data = useSelector(
    (state) => state.callPlayGroup5Mobile.value
  );
  const playgroup6Data = useSelector(
    (state) => state.callPlayGroup6Mobile.value
  );
  const playgroup7Data = useSelector(
    (state) => state.callPlayGroup7Mobile.value
  );
  const playgroupAllData = useSelector(
    (state) => state.callPlayGroupAllMobile.value
  );
  const wingsTamilData = useSelector(
    (state) => state.callWingsTamilMobile.value
  );
  const [paginatedData, setPaginatedData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [applicationArray, setApplicationArray] = useState([
    "PlayGroup1",
    "PlayGroup2",
    "PlayGroup3",
    "PlayGroup4",
    "PlayGroup5",
    "PlayGroup6",
    "PlayGroup7",
    "PlayGroupAll",
    "WingsTamil",
  ]);
  const [selectedApplication, setSelectedApplication] = useState("");
  const currentPageRef = useRef(1);
  const returnTheTotalPages = (appName) => {
    switch (appName) {
      case "PlayGroup1":
        return Math.ceil(playgroupDashboardData.playgroup1 / 20);
        break;
      case "PlayGroup2":
        return Math.ceil(playgroupDashboardData.playgroup2 / 20);
        break;
      case "PlayGroup3":
        return Math.ceil(playgroupDashboardData.playgroup3 / 20);
        break;
      case "PlayGroup4":
        return Math.ceil(playgroupDashboardData.playgroup4 / 20);
        break;
      case "PlayGroup5":
        return Math.ceil(playgroupDashboardData.playgroup5 / 20);
        break;
      case "PlayGroup6":
        return Math.ceil(playgroupDashboardData.playgroup6 / 20);
        break;
      case "PlayGroup7":
        return Math.ceil(playgroupDashboardData.playgroup7 / 20);
        break;
      case "PlayGroupAll":
        return Math.ceil(playgroupDashboardData.playgroupAll / 20);
        break;
      case "WingsTamil":
        return Math.ceil(playgroupDashboardData.wingsTamil / 20);
        break;
    }
  };
  const onPressPreviousNextButton = (action) => {
    let tempDataArray = [];
    let tempPaginatedArray = [];
    let tempCsvData = [];
    switch (selectedApplication) {
      case "PlayGroup1":
        tempDataArray = [...playgroup1Data];
        break;
      case "PlayGroup2":
        tempDataArray = [...playgroup2Data];
        break;
      case "PlayGroup3":
        tempDataArray = [...playgroup3Data];
        break;
      case "PlayGroup4":
        tempDataArray = [...playgroup4Data];
        break;
      case "PlayGroup5":
        tempDataArray = [...playgroup5Data];
        break;
      case "PlayGroup6":
        tempDataArray = [...playgroup6Data];
        break;
      case "PlayGroup7":
        tempDataArray = [...playgroup7Data];
        break;
      case "PlayGroupAll":
        tempDataArray = [...playgroupAllData];
        break;
      case "WingsTamil":
        tempDataArray = [...wingsTamilData];
        break;
    }
    if (action == "Next") {
      currentPageRef.current += 1;
      let tempOffsetValue = currentPageRef.current * 20 - 20;

      if (currentPageRef.current * 20 > tempDataArray.length) {
        getDataFromServer(selectedApplication, tempOffsetValue);
      } else {
        let tempPaginatedArray = [];
        for (let i = tempOffsetValue; i < tempOffsetValue + 20; i++) {
          tempPaginatedArray.push(tempDataArray[i]);
        }
        for (let i = 0; i < tempPaginatedArray.length; i++) {
          tempCsvData.push({
            sl: (currentPageRef.current - 1) * 20 + i,
            playerName:
              tempPaginatedArray[i]._document.data.value.mapValue.fields
                .player_name.stringValue,
            playerAge:
              tempPaginatedArray[i]._document.data.value.mapValue.fields
                .player_age.stringValue,
            playerSchool:
              tempPaginatedArray[i]._document.data.value.mapValue.fields
                .player_school.stringValue,
            playerMobileNumber:
              "MN:" +
              tempPaginatedArray[
                i
              ]._document.data.value.mapValue.fields.player_mobile_number.stringValue.toString(),
            playerTown:
              tempPaginatedArray[i]._document.data.value.mapValue.fields
                .player_town.stringValue,
            playingSince:
              new Date(
                tempPaginatedArray[
                  i
                ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
              ).getDate() +
              "-" +
              (new Date(
                tempPaginatedArray[
                  i
                ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
              ).getMonth() +
                1) +
              "-" +
              new Date(
                tempPaginatedArray[
                  i
                ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
              ).getFullYear(),
          });
        }
        setPaginatedData(tempPaginatedArray);
        setCSVData(tempCsvData);
      }
    } else if (action == "Previous") {
      currentPageRef.current -= 1;
      let tempOffsetValue = currentPageRef.current * 20 - 20;
      let tempDataArray = [];
      let tempPaginatedArray = [];
      let tempCsvData = [];
      switch (selectedApplication) {
        case "PlayGroup1":
          tempDataArray = [...playgroup1Data];
          break;
        case "PlayGroup2":
          tempDataArray = [...playgroup2Data];
          break;
        case "PlayGroup3":
          tempDataArray = [...playgroup3Data];
          break;
        case "PlayGroup4":
          tempDataArray = [...playgroup4Data];
          break;
        case "PlayGroup5":
          tempDataArray = [...playgroup5Data];
          break;
        case "PlayGroup6":
          tempDataArray = [...playgroup6Data];
          break;
        case "PlayGroup7":
          tempDataArray = [...playgroup7Data];
          break;
        case "PlayGroupAll":
          tempDataArray = [...playgroupAllData];
          break;
        case "WingsTamil":
          tempDataArray = [...wingsTamilData];
          break;
      }
      for (let i = tempOffsetValue; i < tempOffsetValue + 20; i++) {
        tempPaginatedArray.push(tempDataArray[i]);
      }
      setPaginatedData(tempPaginatedArray);
      for (let i = 0; i < tempPaginatedArray.length; i++) {
        tempCsvData.push({
          sl: (currentPageRef.current - 1) * 20 + i,
          playerName:
            tempPaginatedArray[i]._document.data.value.mapValue.fields
              .player_name.stringValue,
          playerAge:
            tempPaginatedArray[i]._document.data.value.mapValue.fields
              .player_age.stringValue,
          playerSchool:
            tempPaginatedArray[i]._document.data.value.mapValue.fields
              .player_school.stringValue,
          playerMobileNumber:
            "MN:" +
            tempPaginatedArray[
              i
            ]._document.data.value.mapValue.fields.player_mobile_number.stringValue.toString(),
          playerTown:
            tempPaginatedArray[i]._document.data.value.mapValue.fields
              .player_town.stringValue,
          playingSince:
            new Date(
              tempPaginatedArray[
                i
              ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
            ).getDate() +
            "-" +
            (new Date(
              tempPaginatedArray[
                i
              ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
            ).getMonth() +
              1) +
            "-" +
            new Date(
              tempPaginatedArray[
                i
              ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
            ).getFullYear(),
        });
      }
      setCSVData(tempCsvData);
    }
  };
  const getDataFromServer = async (collectionName, offsetValue) => {
    console.log(`Fetch ${collectionName} from server. ${offsetValue}`);
    let tempDataArray = [];
    switch (collectionName) {
      case "PlayGroup1":
        tempDataArray = [...playgroup1Data];
        break;
      case "PlayGroup2":
        tempDataArray = [...playgroup2Data];
        break;
      case "PlayGroup3":
        tempDataArray = [...playgroup3Data];
        break;
      case "PlayGroup4":
        tempDataArray = [...playgroup4Data];
        break;
      case "PlayGroup5":
        tempDataArray = [...playgroup5Data];
        break;
      case "PlayGroup6":
        tempDataArray = [...playgroup6Data];
        break;
      case "PlayGroup7":
        tempDataArray = [...playgroup7Data];
        break;
      case "PlayGroupAll":
        tempDataArray = [...playgroupAllData];
        break;
      case "WingsTamil":
        tempDataArray = [...wingsTamilData];
        break;
    }

    let colRef = collection(db, collectionName);
    let q = null;
    if (offsetValue != null) {
      q = query(
        colRef,
        orderBy("player_date_of_creation", "desc"),
        startAfter(tempDataArray[(currentPageRef.current - 1) * 20 - 1]),
        limit(20)
      );
    } else if (offsetValue == null) {
      q = query(colRef, orderBy("player_date_of_creation", "desc"), limit(20));
    }
    const docSsp = await getDocs(q);
    let tempPaginatedArray = [];
    switch (collectionName) {
      case "PlayGroup1":
        tempDataArray = [...playgroup1Data];
        break;
      case "PlayGroup2":
        tempDataArray = [...playgroup2Data];
        break;
      case "PlayGroup3":
        tempDataArray = [...playgroup3Data];
        break;
      case "PlayGroup4":
        tempDataArray = [...playgroup4Data];
        break;
      case "PlayGroup5":
        tempDataArray = [...playgroup5Data];
        break;
      case "PlayGroup6":
        tempDataArray = [...playgroup6Data];
        break;
      case "PlayGroup7":
        tempDataArray = [...playgroup7Data];
        break;
      case "PlayGroupAll":
        tempDataArray = [...playgroupAllData];
        break;
      case "WingsTamil":
        tempDataArray = [...wingsTamilData];
        break;
    }
    for (let i = 0; i < docSsp._snapshot.docChanges.length; i++) {
      tempDataArray.push(docSsp.docs[i]);
      tempPaginatedArray.push(docSsp.docs[i]);
    }
    setPaginatedData(tempPaginatedArray);
    let tempCsvData = [];
    for (let i = 0; i < tempPaginatedArray.length; i++) {
      tempCsvData.push({
        sl: (currentPageRef.current - 1) * 20 + i,
        playerName:
          tempPaginatedArray[i]._document.data.value.mapValue.fields.player_name
            .stringValue,
        playerAge:
          tempPaginatedArray[i]._document.data.value.mapValue.fields.player_age
            .stringValue,
        playerSchool:
          tempPaginatedArray[i]._document.data.value.mapValue.fields
            .player_school.stringValue,
        playerMobileNumber:
          "MN:" +
          tempPaginatedArray[
            i
          ]._document.data.value.mapValue.fields.player_mobile_number.stringValue.toString(),
        playerTown:
          tempPaginatedArray[i]._document.data.value.mapValue.fields.player_town
            .stringValue,
        playingSince:
          new Date(
            tempPaginatedArray[
              i
            ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
          ).getDate() +
          "-" +
          (new Date(
            tempPaginatedArray[
              i
            ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
          ).getMonth() +
            1) +
          "-" +
          new Date(
            tempPaginatedArray[
              i
            ]._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
          ).getFullYear(),
      });
    }
    setCSVData(tempCsvData);
    switch (collectionName) {
      case "PlayGroup1":
        dispatch(populate_playgroup1_mobile(tempDataArray));
        break;
      case "PlayGroup2":
        dispatch(populate_playgroup2_mobile(tempDataArray));
        break;
      case "PlayGroup3":
        dispatch(populate_playgroup3_mobile(tempDataArray));
        break;
      case "PlayGroup4":
        dispatch(populate_playgroup4_mobile(tempDataArray));
        break;
      case "PlayGroup5":
        dispatch(populate_playgroup5_mobile(tempDataArray));
        break;
      case "PlayGroup6":
        dispatch(populate_playgroup6_mobile(tempDataArray));
        break;
      case "PlayGroup7":
        dispatch(populate_playgroup7_mobile(tempDataArray));
        break;
      case "PlayGroupAll":
        dispatch(populate_playgroupall_mobile(tempDataArray));
        break;
      case "WingsTamil":
        dispatch(populate_wingstamil_mobile(tempDataArray));
        break;
    }
  };
  const onSelectedSubject = (collectionName) => {
    switch (collectionName) {
      case "PlayGroup1":
        if (playgroup1Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup2":
        if (playgroup2Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup3":
        if (playgroup3Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup4":
        if (playgroup4Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup5":
        if (playgroup5Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup6":
        if (playgroup6Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroup7":
        if (playgroup7Data.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "PlayGroupAll":
        if (playgroupAllData.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
      case "WingsTamil":
        if (wingsTamilData.length == 0) {
          getDataFromServer(collectionName, null);
        } else {
        }
        break;
    }
  };
  const returnThePaddingDate = (val) => {
    if (val.toString().length < 2) {
      return val.toString().padStart(2, 0);
    } else {
      val.toString();
    }
  };
  const onExportThisPageClicked = () => {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "98%",
          height: "98%",
          border: "1px solid black",
          padding: "0.25%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            height: "100%",

            overflowY: "auto",
            rowGap: 5,
          }}
        >
          {applicationArray.map((item, index) => (
            <button
              key={index}
              style={{
                height: 100,
                fontSize: 20,
                fontWeight: "bold",
                borderRadius: 9,
                flex: "none",
                backgroundColor:
                  selectedApplication == item ? "#022C43" : "#F5F5F5",
                color: selectedApplication == item ? "#F5F5F5" : "#022C43",
              }}
              disabled={selectedApplication == item}
              onClick={() => {
                currentPageRef.current = 1;
                setSelectedApplication(item);
                onSelectedSubject(item);
              }}
            >
              {item}
            </button>
          ))}
        </div>
        <div
          style={{
            width: "79%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "95%",
              alignItems: "center",
              paddingLeft: "2.5%",
              paddingRight: "2.5%",
            }}
          >
            {" "}
            <img
              src={BackArrowIcon}
              style={{
                width: 50,
                height: 50,
                object: "contain",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
            <h1>
              {selectedApplication.length > 0
                ? selectedApplication
                : "Select an application in order to view the users list."}
            </h1>
            <div
              style={{ width: 50, height: 50, backgroundColor: "transparent" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "82%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "cyan",
              overflow: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Sl.</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>School Name</th>
                  <th>Mobile Number</th>
                  <th>Town</th>
                  <th>Playing Since</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr key={index}>
                    <td>{(currentPageRef.current - 1) * 20 + index}</td>
                    <td>
                      {
                        item._document.data.value.mapValue.fields.player_name
                          .stringValue
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        item._document.data.value.mapValue.fields.player_age
                          .stringValue
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        item._document.data.value.mapValue.fields.player_school
                          .stringValue
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        item._document.data.value.mapValue.fields
                          .player_mobile_number.stringValue
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        item._document.data.value.mapValue.fields.player_town
                          .stringValue
                      }
                    </td>
                    <td>
                      {new Date(
                        item._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
                      ).getDate() +
                        "-" +
                        (new Date(
                          item._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
                        ).getMonth() +
                          1) +
                        "-" +
                        new Date(
                          item._document.data.value.mapValue.fields.player_date_of_creation.timestampValue
                        ).getFullYear()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "98%",

              justifyContent: "space-between",
            }}
          >
            <h3>
              {selectedApplication.length > 0
                ? currentPageRef.current +
                  " of " +
                  returnTheTotalPages(selectedApplication)
                : null}
            </h3>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                //  justifyContent: "flex-end",
                alignItems: "center",
                columnGap: 10,
                //height: "10%",
                // alignSelf: "flex-end",
              }}
            >
              {selectedApplication != null && csvData.length > 0 ? (
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={`${selectedApplication}Page${currentPageRef.current}.csv`}
                >
                  <img
                    onClick={() => onExportThisPageClicked()}
                    src={ExportExcelIcon}
                    style={{ width: 50, height: 50, object: "contain" }}
                  />
                </CSVLink>
              ) : null}
              {selectedApplication != null && paginatedData.length > 0 ? (
                <button
                  disabled={currentPageRef.current == 1}
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => onPressPreviousNextButton("Previous")}
                >
                  Previous
                </button>
              ) : null}
              {selectedApplication != null && paginatedData.length > 0 ? (
                <button
                  disabled={
                    currentPageRef.current ==
                    returnTheTotalPages(selectedApplication)
                  }
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => onPressPreviousNextButton("Next")}
                >
                  Next
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserList;
