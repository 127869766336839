import {createSlice} from '@reduxjs/toolkit';
export const WingsTamilMobileSlice = createSlice({
  name: 'wingstamilmobile',
  initialState: {
    value: [],
  },
  reducers: {
    populate_wingstamil_mobile: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_wingstamil_mobile} = WingsTamilMobileSlice.actions;
export default WingsTamilMobileSlice.reducer;
