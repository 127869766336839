import RoutingComponent from './Components/RoutingComponent.js';
import {store} from './Components/Redux/store.js'
import { Provider } from 'react-redux';
function App() {
  return (
    <Provider store={store}>
     <RoutingComponent/>
    </Provider>
  );
}

export default App;
