import { configureStore } from "@reduxjs/toolkit";
import PlayGroup1MobileReducer from "./PlayGroup1MobileSlice.js";
import PlayGroup2MobileReducer from "./PlayGroup2MobileSlice.js";
import PlayGroup3MobileReducer from "./PlayGroup3MobileSlice.js";
import PlayGroup4MobileReducer from "./PlayGroup4MobileSlice.js";
import PlayGroup5MobileReducer from "./PlayGroup5MobileSlice.js";
import PlayGroup6MobileReducer from "./PlayGroup6MobileSlice.js";
import PlayGroup7MobileReducer from "./PlayGroup7MobileSlice.js";
import PlayGroupAllMobileReducer from "./PlayGroupAllMobileSlice.js";
import WingsTamilMobileReducer from "./WingsTamilMobileSlice.js";
import PlayGroupDashboardReducer from "./PlayGroupDashboardSlice.js";
export const store = configureStore({
  reducer: {
    callPlayGroup1Mobile: PlayGroup1MobileReducer,
    callPlayGroup2Mobile: PlayGroup2MobileReducer,
    callPlayGroup3Mobile: PlayGroup3MobileReducer,
    callPlayGroup4Mobile: PlayGroup4MobileReducer,
    callPlayGroup5Mobile: PlayGroup5MobileReducer,
    callPlayGroup6Mobile: PlayGroup6MobileReducer,
    callPlayGroup7Mobile: PlayGroup7MobileReducer,
    callPlayGroupAllMobile: PlayGroupAllMobileReducer,
    callWingsTamilMobile: WingsTamilMobileReducer,
    callPlayGroupDashboard: PlayGroupDashboardReducer,
  },
});
